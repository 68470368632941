import { Link } from "gatsby";
// import moment from "moment";
import React from "react";
// import Img from "../../assets/images/eventlisting.png";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";
// import EventsPage from "../Templates/EventsPage";

const EventsListingPage = (props) => {
  // const [isOpen, setIsOpen] = useState(false);
  // console.log(props, "events data");

  return (
    <div className="py-10 max-w-6xl mx-auto px-4 lg:px-10 z-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {props.data.eventsListingPage.edges.map((aa,idx) => {
          // console.log(aa.node.slug.current, "sad");
          return (
            <Link to={aa.node.slug.current} key={idx}>
              <div className="shadow-lg hover:shadow-1xl">
                <div className="">
                  <GatsbyImageCustom
                    id={aa.node.listing_event_image.asset._id}
                    className="w-full"
                    alt="img"
                  />
                </div>
                <div className="h-40">
                  <div className="border bg-gray-100 p-4 shadow-lg min-h-full">
                    <div className="h-16">
                      <div className="text-xl font-bold text-left pb-2 min-h-full">
                        {aa.node.title}
                      </div>
                    </div>
                    {/* <div className="text-base font-medium italic pb-5">
                        { aa.node._rawEventShortDescription[0].children[0].text}
                        </div> */}
                    {/* <div className=" text-xs font-medium pb-3 flex h-10">
                      <div className="pr-2 w-36 max-w-full">
                        {moment(aa.node.event_date_time_detail).format(
                          "dddd DD MMMM"
                        )}
                      </div>

                      <span className="pl-2 border-l-2 border-secondary-darkblue-500">
                        {moment(aa.node.event_date_time_detail).format(
                          "HH:mm A"
                        )}
                      </span>
                    </div> */}
                    <div className=" text-xs font-medium">
                      {aa.node.event_venue_detail}
                    </div>
                    <div className=" text-sm font-bold">
                      {aa.node._rawEventShortDescription[1].children[0].text}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      {/* {isOpen === true ? <EventsPage data={props}/> : ""} */}
    </div>
  );
};

export default EventsListingPage;
