import { graphql } from "gatsby";
import React from "react";
import Errors from "../Components/Errors/Errors";
import EventsListingPage from "../Components/Events/EventsListingPage";
import Layout from "../Components/Layout/Layout";
import EventsHeader from "../Components/Events/EventsHeader";

export const query = graphql`
  query EventsListingPage {
    eventsListingPage: allSanityEventspage {
      edges {
        node {
          _id
          events_type
          event_date_time_detail
          _rawEventShortDescription
          title
          event_venue_detail
          slug {
            current
          }
          listing_event_image {
            asset {
              _id
            }
          }

        }
      }
    }
  }
`;

const evntsListingPage = (props) => {
  // console.log(props,"sad")
  const { errors } = props;
  if (errors) {
    return <Errors errors={errors} />;
  }

  return (
    <>
      <Layout location={props.location} >
        
        <EventsHeader />
        <EventsListingPage data={props.data} />
      </Layout>
    </>
  );
};

export default evntsListingPage;
