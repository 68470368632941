import React from "react";
import HeaderImg from "../../assets/images/events_listing_banner.png"

const EventsHeader = () => {
  return (
    <>
      <div className="relative z-10">
        <img
          style={{ height: "500px" }}
          src={HeaderImg}
          alt="img header"
          className=" w-full filter brightness-50 object-cover"
        />
        <div className="mx-auto max-w-6xl">
          <div className=" absolute left-0 right-0 top-0 bottom-0 m-auto flex justify-center items-center">
            <div className="px-4">
              <div className="-bold lg:text-6xl md:text-4xl  text-2xl text-white text-center">
                Amethhyyst Events
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsHeader;
